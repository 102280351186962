<template>
  <div class="page" id="print">
    <el-row class="outer-row" >
      <el-col :span="24">
        <div class="pdl20div">
          <el-descriptions title="事件信息" :column="1">
            <el-descriptions-item label="事件类型"
              >{{device.eventCodeIdConfirmName}}</el-descriptions-item
            >
            <el-descriptions-item label="地址"
              >{{device.eventAddressConfirm}}</el-descriptions-item
            >
            <el-descriptions-item label="说明">{{device.eventDescribe}}</el-descriptions-item>
            <el-descriptions-item label="现场图片">
                 <div class="imgContain" v-if="device.imgUrl">
                    <img style="width: 100px; height: 100px" :src="fourths" 
                    v-for="(fourths,int4) in device.imgUrl.split(',')" 
                    @click="showImg(fourths)"
                    :key="int4" alt=""/>
                 </div>
                

            </el-descriptions-item>
            <el-descriptions-item label="附件"> 
                 <div class="imgContain" v-if="device.fileUrl">
                    <a href="" target="_black" v-for="(fourths,int4) in device.fileUrl.split(',')"  :key="int4" >
                    {{fourths}}
                    </a>
                  </div>
                 </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="right-part">
          <div class="title">采集信息</div>
          <div class="right-content">
                <!-- <el-button type="primary" plain  size="mini" style="float:right" @click="detailAddress('','container')">打卡位置</el-button> -->
                  <div>
                    <el-descriptions direction='vertical' :column="1" v-for="(item1,second) in form" :key="second">
                      <el-descriptions-item :label="'● '+item1.itemName" :label-style="{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}">
                        <template v-if="item1.inputType==1 || item1.inputType==5">{{item1.note}}</template>
                        <div v-if="item1.inputType==2||item1.inputType==3">
                          <div v-for="item2 in item1.items" :key="'b'+item2.items">
                            <template v-if="item2.isPitchUp">
                              <img class="icon-img" :src="item2.type==1?require('../../assets/normal1.png'):require('../../assets/warning1.png')" />
                              {{item2.content}}
                              <div class="describ" v-if="item2.note" style="min-width: 180px">
                                {{item2.note}}
                              </div>
                              <div class="imgContain" v-if="item2.url">
                                <img style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;" :src="fourths" 
                                v-for="(fourths,int4) in item2.url.split(',')" 
                                @click="showImg(fourths)"
                                :key="int4" alt=""/>
                              </div>
                              <!-- 关联项 -->
                              <!-- <div v-for="item3 in item2.junior" :key="item3.juniorId"> -->
                                <el-descriptions direction='vertical' :column="1" v-for="item3 in item2.junior" :key="item3.juniorId">
                                  <el-descriptions-item :label-style="{color:'#444444',fontWeight: 'bold'}"
                                          :label="'● '+item3.juniorName">
                                    <template v-if="item3.juniorInputType==1 || item3.juniorInputType==5">{{item3.note}}</template>
                                    <div v-if="item3.juniorInputType==2||item3.juniorInputType==3">
                                      <div v-for="item4 in item3.juniors" :key="'b'+item4.juniorsId">
                                        <template  v-if="item4.isPitchUp">
                                          <img class="icon-img" :src="item4.juniorsType==1?require('../../assets/normal1.png'):require('../../assets/warning1.png')" />
                                          {{item4.juniorsContent}}
                                          <div class="describ" v-if="item4.note" style="min-width: 180px">
                                            {{item4.note}}
                                          </div>
                                          <div class="imgContain" v-if="item4.url">
                                            <img style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;" :src="firsts" 
                                            v-for="(firsts,int1) in item4.url.split(',')" 
                                             @click="showImg(firsts)"
                                            :key="int1" alt=""/>
                                          </div>
                                        </template>
                                      </div>
                                    </div>
                                    <div v-if="item3.juniorInputType==4">
                                      <div class="imgContain" v-if="item3.url">
                                        <img style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;" :src="sectids" 
                                        v-for="(sectids,int2) in item3.url.split(',')" 
                                        @click="showImg(sectids)"
                                        :key="int2" alt=""/>
                                      </div>
                                    </div>
                                  </el-descriptions-item>
                                </el-descriptions>
                              <!-- </div> -->
                            </template>
                          </div>
                        </div>
                        <div class="right" v-if="item1.inputType==4">
                          <div class="imgContain" v-if="item1.url">
                            <img style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;" :src="thirdlys" 
                            v-for="(thirdlys,int2) in item1.url.split(',')" 
                             @click="showImg(thirdlys)"
                            :key="int2" alt=""/>
                          </div>
                        </div>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
          
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
 
<script>
import {dataInfo} from "@/RequestPort/checkTask/polling";
export default {
  name: "unresidentDetail",
  data() {
    return {
      activeNames: 0,
      srcList:[],
      // 表单
      form:[],
      dataId:'',
      // 图片
      imgList: [],
      device:{}
    };
  },
  mounted(){
    this.device = JSON.parse(this.$route.query.row)
    if(this.$route.query.id){
      this.dataId = this.$route.query.id
       this.particulars(this.$route.query.id)
     }
  },
  methods: {
    // particulars(val){
    //   dataInfo({id:val}).then(res => {
    //     this.form = res.data

        
    //   })
    // },
    particulars(val){
      let dataids
      // 事件管理传过来的
      if(this.device.eventDataId){
        dataids = this.device.eventDataId
      } else {
        // 巡检结果事件
        dataids = this.device.id
      }
      dataInfo({id:val,dataId:dataids}).then(res => {
        
        this.form = res.data
        this.$nextTick((e)=>{
              this.$print('#print', {
                              afterprint: () => {
                                // this.$router.go(-1)
                                document.getElementById('#print')[0].style.zoom=3;
                              },
                              cancel: () => {
                                this.$router.go(-1)
                              }
                     },63)
          })
      })
    },
    detailAddress(e,index){
      // this.$refs.mapposition.setMapCenter({
      //   checkinLocation: e
      // })
      if (e) {
        this.$nextTick(() => {
          console.log([Number(e.split(' ')[0]),Number(e.split(' ')[1])],index)
          var map = new AMap.Map('container', {
            zoom: 15,//级别
            center: [Number(e.split(' ')[0]),Number(e.split(' ')[1])]
          });
          var marker = new AMap.Marker({
              position:[Number(e.split(' ')[0]),Number(e.split(' ')[1])]//位置
          })
          map.add(marker);//添加到地图
        })
      } else {
        this.$message.error('无打卡位置信息')
      }
    },
    close() {
      this.$router.back(-1)
    },
  },
};
</script>
 
<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
    // height: calc(100vh - 140px);
  overflow: auto;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  .pdl20div {
    /deep/.el-descriptions {
      .el-descriptions__body {
        padding-left: 20px;
      }
    }
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
    .colsite {
      margin-bottom: 20px;
    }
  }
  // .create {
  //   width: 100%;
  //   height: 50px;
  //   // background-color: burlywood;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   .create_size {
  //     font-size: 20px;
  //     font-weight: 500;
  //     color: #252700;
  //     margin-left: 20px;
  //   }
  // }
  .box {
    width: 96%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;

      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        padding: 10px;
        margin-bottom: 20px;
      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.right-part {
  // padding-left: 30px;
  height: 100%;
  // height: calc(100% - 50px);
  overflow: auto;
  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .right-content {
    padding: 18px;
    .first-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      div {
        width: 50%;
        margin-bottom: 29px;
        > span {
          color: #545454;
        }
        button {
          padding: 0;
          margin-left: 40px;
        }
      }
    }
  }
  .infoCollect {
    border: 1px solid #ECECEC;
    padding: 33px;
    .item-title::before {
      content: '| ';
      color: #7CBAFF;
    }
    /deep/.el-descriptions {
      padding: 18px 63px;
      .el-descriptions-item__label:not(.is-bordered-label) {
        color: #545454;
        width: 95px;
      }
      .el-descriptions-item__content {
        display: block;
        .ques1,.ques2 {
          line-height: 30px;
          img {
            width: 16px;
            height: 16px;
            vertical-align: text-top;
          }
        }
        .ques2 {
          padding-left: 20px;
        }
      }
    }
  }
  .imgContain {
    .el-image {
      margin: 0 10px 10px 0;
    }
  }
  .userConfirm {
    margin-top: 24px;
    display: flex;
    .left {
      margin-right: 32px;
    }
  }
}
// .outer-row {
  // height: calc(100% - 50px);
  // .el-col {
  //   height: 100%;
  // }
  // .el-col:first-child {
  //   border-right: 1px solid #f1f1f1;
  // }
// }
/deep/.el-descriptions__title::before,
.title::before {
  content: "| ";
  font-weight: bold;
  color: #1082ff;
}
.icon-img {
  width: 16px;
  height: 16px;
}
.describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  }
  .imgContain{
	margin: 0 14px;
  }
</style>